export default [
  {
    path: '/home',
    name: 'home-page',
    component: () => import('@/views/home-page/home/Home.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/product',
    name: 'home-page-product',
    component: () => import('@/views/home-page/product/Product.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/myspace',
    name: 'home-page-space',
    component: () => import('@/views/home-page/my-space/MySpace.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/about',
    name: 'home-page-about',
    component: () => import('@/views/home-page/about/About.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  // Tracking
  {
    path: '/pt/:id',
    name: 'apps-pt-preview',
    component: () => import('@/views/home-page/pt/ProductionTrackingPreview.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/phone/home',
    name: 'home-page',
    component: () => import('@/views/home-page-phone/home/Home.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/phone/product',
    name: 'home-page-product',
    component: () => import('@/views/home-page-phone/product/Product.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/phone/myspace',
    name: 'home-page-space',
    component: () => import('@/views/home-page-phone/my-space/MySpace.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/phone/about',
    name: 'home-page-about',
    component: () => import('@/views/home-page-phone/about/About.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/phone/pt/:id',
    name: 'apps-pt-preview',
    component: () => import('@/views/home-page-phone/pt/ProductionTrackingPreview.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
]
