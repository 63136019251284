//const baseURL = 'https://supply-chain.com.cn/api/';
const baseURL = 'https://www.paile-tech.com/api/';


export default {
  // Endpoints
  globalUrl: baseURL,
  loginEndpoint: baseURL + 'auth/login/',
  registerEndpoint: baseURL + 'auth/signup/',
  refreshEndpoint: baseURL + 'auth/refresh-token/',
  logoutEndpoint: 'auth/logout/',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'JWT',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',


}
